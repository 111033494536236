import axios from 'axios'
import history from '../components/history';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

var CancelToken = axios.CancelToken;
var cancel;
var siteStore;


//set global info
global.Processing = {
    // 防止401错误时死循环
    HasRedirected: null,
    // 跳转链接
    RedirectUrl: null
}

const AUTH_TOKEN = 'AUTHTOKEN';
export function setCookie(c_name, value, expiredays) {
    cookies.set(c_name, value, { path: '/' });
}

//取回cookie
export function getCookie(c_name) {
    let _value = cookies.get(c_name);
    if (_value === undefined || _value === null)
        return "";
    return _value;
}


export function GetSiteToken() {
    let _token = "";
    // try {
    //     _token = localStorage.getItem(AUTH_TOKEN);
    // } catch (ex) {
    _token = getCookie(AUTH_TOKEN);
    //}
    return _token === undefined || _token === null ? '' : _token;
}
export function SetSiteToken(token) {
    // try {
    //     localStorage.setItem(AUTH_TOKEN, token)
    // } catch (ex) {
    setCookie(AUTH_TOKEN, token);
    //}
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}


export function SetToLocal(key, value) {
    // try {
    //     localStorage.setItem(key, value);
    // } catch (ex) {
    setCookie(key, value);
    //}
}
export function GetFromLocal(key) {
    // try {
    //     return localStorage.getItem(key);
    // } catch (ex) {
    return getCookie(key);
    //}
}

export function LogOut() {
    SetSiteToken('');
    history.push('/');
}

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    siteStore.config.changeLoading(true);
    return config;
}, function (error) {
    // 对请求错误做些什么
    siteStore.config.changeLoading(false);
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    siteStore.config.changeLoading(false);
    return response;
}, (error) => {
    siteStore.config.changeLoading(false);
    if (cancel)
        cancel()
    // 对响应错误做点什么
    if (error.response !== undefined) {
        let currentUrl = GetCurrentLocation();
        switch (error.response.status) {
            case 400:
                alert(error.response.data.message);
                break;
            case 401:
                global.Processing.RedirectUrl = GetCurrentLocation();
                SetSiteToken('');
                if (currentUrl.indexOf('/admin') !== -1) {
                    DoRedirect('/backendlogin')
                } else {
                    DoRedirect('/')
                }
                break;
            case 403:
                siteStore.config.changeMessage(error.response.data.message, 'error');
                global.Processing.RedirectUrl = GetCurrentLocation();
                if (currentUrl.indexOf('/admin') !== -1) {
                    DoRedirect('/backendlogin')
                } else {
                    DoRedirect('/')
                }
                break;
            default:
                siteStore.config.changeMessage(error.response.data.message, 'error');
                break;
        }
    }
    return Promise.reject(error);
});
function GetCurrentLocation() {
    let _path = history.location.pathname
    if (history.location.search !== undefined && history.location.search !== null && history.location.search !== '') {
        _path += history.location.search;
    }
    return _path;
}
function DoRedirect(path) {
    if (global.Processing.HasRedirected === null) {
        setTimeout(() => {
            global.Processing.HasRedirected = true;
            history.replace(path);
        }, 500)
    } else {
        global.Processing.HasRedirected = null;
    }
}


//duration转时长
export function GetMinutesAndSeconds(ms) {
    if (/^\d+$/.test(ms)) {
        let minutes = parseInt(ms / 1000 / 60, 10)
        let seconds = parseInt(ms / 1000, 10) - minutes * 60;
        if (seconds.toString().length === 1) {
            seconds = "0" + seconds;
        }
        return minutes + ":" + seconds;
    }
    return ms;
}

export function GetRootUrl() {
    return 'https://e.tangsem.com/';//'https://localhost:44376/';// 'https://localhost:7232';//
}

let _SiteToken = GetSiteToken();
if (_SiteToken === undefined || _SiteToken === 'undefined' || _SiteToken === null)
    _SiteToken = '';
axios.defaults.baseURL = GetRootUrl();
var _offset = -(new Date().getTimezoneOffset() / 60);
console.log(_offset)
axios.defaults.headers.common['Authorization'] = `Bearer ${_SiteToken}`;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common['ClientTimeZone'] = _offset;
export function Get(url) {
    return axios.get(url, {
        cancelToken: new CancelToken(function executor(c) {
            // executor 函数接收一个 cancel 函数作为参数
            cancel = c;
        })
    })
        .catch(function (thrown) {
            if (axios.isCancel(thrown)) {
                return Promise.reject(thrown);
            } else {
                return Promise.reject(thrown);
            }
        });
}

export function GetBlob(url) {
    return axios({
        url: url,
        method: 'get',
        responseType: 'blob',
        cancelToken: new CancelToken(function executor(c) {
            // executor 函数接收一个 cancel 函数作为参数
            cancel = c;
        })
    })
        .catch(function (thrown) {
            if (axios.isCancel(thrown)) {
                return Promise.reject(thrown);
            } else {
                return Promise.reject(thrown);
            }
        });
}
export function PostBlob(url, data) {
    // return axios({
    //     url: url,
    //     method: 'post',
    //     responseType: 'blob',
    //     data:data,
    //     cancelToken: new CancelToken(function executor(c) {
    //         // executor 函数接收一个 cancel 函数作为参数
    //         cancel = c;
    //     })
    // })
    return axios.post(url, data, {
        responseType: 'blob',
        cancelToken: new CancelToken(function executor(c) {
            // executor 函数接收一个 cancel 函数作为参数
            cancel = c;
        })
    })
        .catch(function (thrown) {
            if (axios.isCancel(thrown)) {
                return Promise.reject(thrown);
            } else {
                return Promise.reject(thrown);
            }
        });
}

export function Post(url, data) {
    return axios.post(url, data, {
        cancelToken: new CancelToken(function executor(c) {
            // executor 函数接收一个 cancel 函数作为参数
            cancel = c;
        })
    }).catch(function (thrown) {
        if (axios.isCancel(thrown)) {
            return Promise.reject(thrown);
        } else {
            return Promise.reject(thrown);
        }
    });
}

export function DownloadBlob(res) {
    if (!res.data) {
        return
    }

    let fileName = res.headers["content-type"];

    if (fileName.indexOf('filename') !== -1) {
        fileName = fileName.substring(fileName.lastIndexOf('=') + 1);
    }
    var type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (fileName.indexOf('zip') !== -1) {
        type = 'application/x-zip-compressed'
    }
    var blob = new Blob([res.data], { type: type })
    var url = window.URL.createObjectURL(blob);
    var aLink = document.createElement("a");
    aLink.style.display = "none";
    aLink.href = url;
    aLink.setAttribute("download", fileName);
    document.body.appendChild(aLink);
    aLink.click();
    document.body.removeChild(aLink); //下载完成移除元素
    window.URL.revokeObjectURL(url); //释放掉blob对象
}

export function InitStore(store) {
    siteStore = store;
}


export function Common_GetSearchParams(search) {
    let params = {};
    if (search !== undefined && search !== '') {
        search = search.substring(1);
        let ps = search.split('&');
        for (var i = 0; i < ps.length; i++) {
            let m = ps[i].split('=');
            if (m.length > 2) {
                //value中含有=的情况
                let z = ps[i].substring(0, ps[i].indexOf('='));
                let v = ps[i].substring(ps[i].indexOf('=') + 1);
                m = [z, v];
            }
            let hasKey = false;
            for (var key in params) {
                if (key === m[0].trim()) {
                    hasKey = true;
                }
            }

            if (hasKey) {
                var old = params[m[0].trim()];
                if (typeof old === 'object') {
                    params[m[0].trim()].push(m[1].trim());
                } else {
                    let newVs = [];
                    newVs.push(params[m[0]].trim());
                    newVs.push(m[1].trim());
                    params[m[0].trim()] = newVs;
                }
            } else {
                params[m[0].trim()] = m[1].trim();
            }
        }
    }
    return params;
}
export function isWeiXin() {
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase();
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
    } else {
        return false;
    }
}

export function CategoryBreadcrumnb(data, overrideDefualt) {
    let defaultCategory = [{ key: 0, value: 0, title: 'No Parent', children: [] }];
    if (overrideDefualt !== undefined) {
        defaultCategory = overrideDefualt;
    }
    if (data === null || data.length === 0) {
        return [{ key: 0, value: 0, title: 'No Parent', children: [] }];
    }
    var parents = data.filter(p => p.parentId === 0);
    let newData = parents.map((item, index) => {
        let newItem = { key: item.id, value: item.id, title: item.name, children: [] };
        return iterationCategory(newItem, data);
    });
    return defaultCategory.concat(newData);
}

function iterationCategory(parent, allData) {
    var subs = allData.filter(p => p.parentId === parent.key);
    if (subs !== null && subs.length > 0) {
        let childrens = subs.map((item, index) => {
            let newItem = { key: item.id, value: item.id, title: item.name, children: [] };
            return iterationCategory(newItem, allData);
        });
        parent.children = childrens;
    }
    return parent;
}
const crypto = require("crypto");
const ETSY_RedirectUri = "http://etsy.justyling.com/oauth/redirect";
const ETSY_ClientID = "31mpwhsxdvrns6xj6nnnxnfy";
const base64URLEncode = (str) =>
    str
        .toString("base64")
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "");
const sha256 = (buffer) => crypto.createHash("sha256").update(buffer).digest();
const codeVerifier = base64URLEncode(crypto.randomBytes(32));
const codeChallenge = base64URLEncode(sha256(codeVerifier));
export function CreateEtsyAuthorizationUrl() {
    const state = Math.random().toString(36).substring(7);
    return `https://www.etsy.com/oauth/connect?response_type=code&redirect_uri=${ETSY_RedirectUri}&scope=email_r&client_id=${ETSY_ClientID}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
}


export function YearMonthDayFormat(date) {
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString();
    var day = (date.getDate()).toString();
    if (month.length === 1) {
        month = "0" + month;
    }
    if (day.length === 1) {
        day = "0" + day;
    }
    var dateTime = year + "-" + month + "-" + day;
    return dateTime;
}
export function DateTimeLongFormat(date) {
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString();
    var day = (date.getDate()).toString();
    var minutes = date.getMinutes().toString();
    var seconds = date.getSeconds().toString();
    if (month.length === 1) {
        month = "0" + month;
    }
    if (day.length === 1) {
        day = "0" + day;
    }
    if (minutes.length === 1)
        minutes = "0" + minutes;

    if (seconds.length === 1)
        seconds = "0" + seconds;
    var dateTime = year + "-" + month + "-" + day + " " + minutes + ":" + seconds;
    return dateTime;
}

const EyeColors = ["E01", "E02", "E03", "E04", "E05", "E06", "E07", "E08"];
const SkinColors = ["S01", "S02", "S03", "S04", "S05", "S06", "S07", "S08"];
const HairColors = ["H01", "H02", "H03", "H04", "H05", "H06", "H07", "H08", "Bald"];

export const BobbleColors = {
    EyeColors,
    SkinColors,
    HairColors
}

export function DataUrlToBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

export const ProofParentItemName = [{ name: '第一人', value: 'Person #1' },
{ name: '第二人', value: 'Person #2' },
{ name: '第三人', value: 'Person #3' },
{ name: '第四人', value: 'Person #4' },
{ name: '第五人', value: 'Person #5' },
{ name: '第六人', value: 'Person #6' },
{ name: '第七人', value: 'Person #7' },
{ name: '第八人', value: 'Person #8' }]
export const ProofParentItemNameForCrystal = [{ name: '第一个', value: 'No.1' },
{ name: '第二个', value: 'No.2' },
{ name: '第三个', value: 'No.3' },
{ name: '第四个', value: 'No.4' },
{ name: '第五个', value: 'No.5' },
{ name: '第六个', value: 'No.6' },
{ name: '第七个', value: 'No.7' },
{ name: '第八个', value: 'No.8' }]


export const ReplaceProofTag = (_value) => {
    if (_value === undefined || _value === null)
        return "";
    var reg = /Hair\sColor/g
    _value = _value.replace(reg, '头发颜色');
    reg = /Eye\sColor/g
    _value = _value.replace(reg, '眼睛颜色');
    reg = /Skin\sColor/g
    _value = _value.replace(reg, '皮肤颜色');
    reg = /(Glasses)\s([\d]{1}):(Yes|No)/g
    if (reg.test(_value)) {
        reg.exec(_value);
        var glass = RegExp.$3;
        _value = _value.replace(reg, `是否戴眼镜 $2:${(glass === 'Yes' ? '是' : '否')}`);
    }
    reg = /Personalization:Not requested on this item./g;
    _value = _value.replace(reg, '');

    reg = /headOnly/g
    _value = _value.replace(reg, '只要头');
    reg = /headToToe/g
    _value = _value.replace(reg, '全身定制');
    return _value;
}